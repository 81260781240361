import React from "react"
import {I18NextWrapper} from "../loaders/i18nextInit";
import "../styles/index.css"
import {Loading} from "../components/Loading";
import PrivacyComponent from "../components/Privacy";

const Privacy = () => {
    return (
        <>
            <I18NextWrapper loading={<Loading/>}>
                <PrivacyComponent/>
            </I18NextWrapper>
        </>
    )
}

export default Privacy
