import React from 'react';
import {Col, Row} from "antd";
import {sectionLayout} from "../utils/constants";
import { config } from "../config"


function Privacy() {

    return (
        <Row>
            <Col {...sectionLayout} style={{height: '100vh', minHeight: '400px'}}>
                <iframe
                  width="100%"
                  height="100%"
                  src={config.links.drive.privacy} />
            </Col>
        </Row>
    );
}

export default Privacy;
